import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const DynamicTitleUpdater = () => {
  // Title map for different keywords, including the new product list
  const titleMap = {
    'gi-conduit': 'TechnoFlex | Premium GI Conduit Pipes',
    '20mm-gi-conduit': 'TechnoFlex | 20mm GI Conduit for Durable Electrical Installations',
    '25mm-gi-conduit': 'TechnoFlex | 25mm GI Conduit for Secure Wiring',
    '32mm-gi-conduit': 'TechnoFlex | Reliable 32mm GI Conduit Pipes',
    '40mm-gi-conduit': 'TechnoFlex | Heavy Duty 40mm GI Conduit',
    '50mm-gi-conduit': 'TechnoFlex | Large-Scale 50mm GI Conduit Solutions',
    'ms-conduit': 'TechnoFlex | Reliable MS Conduit Pipes',
    '20mm-ms-conduit': 'TechnoFlex | 20mm MS Conduit for Electrical Protection',
    '25mm-ms-conduit': 'TechnoFlex | High-Quality 25mm MS Conduit',
    '40mm-ms-conduit': 'TechnoFlex | Durable 40mm MS Conduit Solutions',
    '50mm-ms-conduit': 'TechnoFlex | Heavy-Duty 50mm MS Conduit Pipes',
    'emt-conduit': 'TechnoFlex | EMT Conduit Systems & Solutions',
    '20mm-emt-conduit': 'TechnoFlex | Standard 20mm EMT Conduit Pipes',
    '25mm-emt-conduit': 'TechnoFlex | Flexible 25mm EMT Conduit Systems',
    '32mm-emt-conduit': 'TechnoFlex | Efficient 32mm EMT Conduit for Installations',
    '40mm-emt-conduit': 'TechnoFlex | Heavy-Duty 40mm EMT Conduit Pipes',
    '50mm-emt-conduit': 'TechnoFlex | Robust 50mm EMT Conduit Systems',
    'gi-flexible-conduit': 'TechnoFlex | GI Flexible Conduit for Electrical Safety',
    '1-2-inch-gi-flexible-conduit': 'TechnoFlex | 1/2 Inch GI Flexible Conduit Pipes',
    '3-4-inch-gi-flexible-conduit': 'TechnoFlex | 3/4 Inch GI Flexible Conduit for Versatile Applications',
    '1-inch-gi-flexible-conduit': 'TechnoFlex | High-Quality 1 Inch GI Flexible Conduit',
    'pvc-coated-flexible-conduit': 'TechnoFlex | PVC Coated Flexible Conduits',
    '1-2-inch-pvc-coated-gi-flexible-conduit': 'TechnoFlex | 1/2 Inch PVC Coated Flexible Conduit',
    '3-4-inch-pvc-coated-gi-flexible-conduit': 'TechnoFlex | 3/4 Inch PVC Coated Flexible Conduit',
    'liquid-tight-flexible-conduit': 'TechnoFlex | Liquid Tight Flexible Conduit Systems',
    '1-2-inch-liquid-tight-flexible-conduit': 'TechnoFlex | 1/2 Inch Liquid Tight Flexible Conduit',
    '3-4-inch-liquid-tight-flexible-conduit': 'TechnoFlex | 3/4 Inch Liquid Tight Flexible Conduit',
    'stainless-steel-flexible-conduit': 'TechnoFlex | Stainless Steel Flexible Conduit Solutions',
    '1-2-inch-stainless-steel-flexible-conduit': 'TechnoFlex | 1/2 Inch Stainless Steel Flexible Conduit',
    '3-4-inch-stainless-steel-flexible-conduit': 'TechnoFlex | 3/4 Inch Stainless Steel Flexible Conduit',
    'aluminum-flexible-conduit': 'TechnoFlex | Lightweight Aluminum Flexible Conduits',
    '1-2-inch-aluminum-flexible-conduit': 'TechnoFlex | 1/2 Inch Aluminum Flexible Conduit',
    '3-4-inch-aluminum-flexible-conduit': 'TechnoFlex | 3/4 Inch Aluminum Flexible Conduit',
    'polyamide-flexible-conduit': 'TechnoFlex | Durable Polyamide Flexible Conduits',
    '1-2-inch-polyamide-conduit': 'TechnoFlex | 1/2 Inch Polyamide Flexible Conduit',
    '3-4-inch-polyamide-conduit': 'TechnoFlex | 3/4 Inch Polyamide Flexible Conduit',
    'pvc-rigid-conduit': 'TechnoFlex | PVC Rigid Conduit Pipes',
    '20mm-pvc-rigid-conduit': 'TechnoFlex | 20mm PVC Rigid Conduit for Compact Installations',
    '25mm-pvc-rigid-conduit': 'TechnoFlex | 25mm PVC Rigid Conduit for Secure Applications',
    '32mm-pvc-conduit': 'TechnoFlex | Heavy-Duty 32mm PVC Conduit Systems',
    'industrial-products': 'TechnoFlex | Industrial Electrical Products',
    'class-3-conduit': 'TechnoFlex | Class 3 Conduit Pipes for Reliable Electrical Protection',
    'class-4-conduit': 'TechnoFlex | Class 4 Conduit Pipes for Heavy-Duty Electrical Installations',
    'liquid-tight-flexible-conduit': 'TechnoFlex | Liquid Tight Flexible Conduit for Superior Protection',
    // Adding the new products
    'one-way-gi-junction-box': 'TechnoFlex | One Way GI Junction Box for Reliable Connections',
    'two-way-gi-junction-box': 'TechnoFlex | Two Way GI Junction Box for Electrical Installations',
    'three-way-gi-junction-box': 'TechnoFlex | Three Way GI Junction Box for Complex Wiring Solutions',
    'gi-bend': 'TechnoFlex | High-Quality GI Bends for Electrical Conduits',
    'gi-coupler': 'TechnoFlex | Durable GI Couplers for Secure Connections',
    'saddle-bar': 'TechnoFlex | Saddle Bar for Efficient Conduit Installation',
    'half-saddle': 'TechnoFlex | Half Saddle for Secure Conduit Fittings',
    'gi-lock-nut': 'TechnoFlex | GI Lock Nut for Strong Electrical Connections',
    'gi-tee': 'TechnoFlex | GI Tee for Reliable Electrical Conduit Branching',
    'gi-elbow': 'TechnoFlex | GI Elbow for Reliable Electrical Conduit Routing',
    'ms-bends': 'TechnoFlex | MS Bends for Flexible Electrical Installations',
    'ms-surface-junction-boxes': 'TechnoFlex | MS Surface Junction Boxes for Durable Wiring Solutions',
    'ms-deep-junction-boxes': 'TechnoFlex | MS Deep Junction Boxes for Complex Installations',
    'ms-couplers': 'TechnoFlex | MS Couplers for Secure Electrical Connections',
    'ms-inspection-bends': 'TechnoFlex | MS Inspection Bends for Easy Maintenance',
    'ms-elbows': 'TechnoFlex | MS Elbows for Efficient Conduit Routing',
    'ms-inspection-t': 'TechnoFlex | MS Inspection T for Convenient Wiring',
    'zinc-die-cast-coupler': 'TechnoFlex | Zinc Die Cast Couplers for High-Strength Connections',
    'nickel-plated-brass-adaptor': 'TechnoFlex | Nickel Plated Brass Adaptor for Durable Fittings',
    'liquid-tight-straight-connector': 'TechnoFlex | Liquid Tight Straight Connectors for Secure Connections',
    'mount-clamp': 'TechnoFlex | Mount Clamp for Easy Conduit Installation',
    'four-way-gi-junction-box': 'TechnoFlex | Four Way GI Junction Box for Complex Electrical Systems',
    'circular-junction-box-one-way': 'TechnoFlex | Circular Junction Box One Way for Secure Wiring',
    'circular-doom-cover': 'TechnoFlex | Circular Doom Cover for Conduit Protection',
    'circular-junction-box-two-way': 'TechnoFlex | Circular Junction Box Two Way for Electrical Wiring',
    'circular-back-outlet': 'TechnoFlex | Circular Back Outlet for Safe Conduit Connections',
    'circular-junction-box-three-way': 'TechnoFlex | Circular Junction Box Three Way for Electrical Systems',
    'circular-junction-box-four-way': 'TechnoFlex | Circular Junction Box Four Way for Efficient Wiring',
    'circular-junction-box-u-type': 'TechnoFlex | Circular Junction Box U Type for Electrical Installations',
    'circular-junction-box-angle-way': 'TechnoFlex | Circular Junction Box Angle Way for Flexible Connections',
    'circular-junction-box-h-type': 'TechnoFlex | Circular Junction Box H Type for Electrical Installations',
    'spacer-bar-saddle': 'TechnoFlex | Spacer Bar Saddle for Effective Conduit Mounting',
    'normal-bend': 'TechnoFlex | Normal Bend for Efficient Electrical Conduit Routing',
    'plain-saddle': 'TechnoFlex | Plain Saddle for Simple Conduit Installation',
    'hex-lock-nut': 'TechnoFlex | Hex Lock Nut for Secure Electrical Connections',
    'gi-coupler': 'TechnoFlex | GI Coupler for Safe and Strong Electrical Fittings',
    'gi-doom-cover': 'TechnoFlex | GI Doom Cover for Conduit Protection',
    'hex-reducer': 'TechnoFlex | Hex Reducer for Versatile Conduit Fittings',
    'earthing-strip-saddle': 'TechnoFlex | Earthing Strip Saddle for Electrical Safety',
    'switch-and-socket-box': 'TechnoFlex | Switch and Socket Box for Reliable Installations',
    'emt-one-hole-strap': 'TechnoFlex | EMT One Hole Strap for Conduit Support',
    'emt-two-hole-strap': 'TechnoFlex | EMT Two Hole Strap for Conduit Mounting',
    'squeeze-connector': 'TechnoFlex | Squeeze Connector for Secure Electrical Connections',
    'set-screw-connector': 'TechnoFlex | Set Screw Connector for Strong Fittings',
    'set-screw-coupling': 'TechnoFlex | Set Screw Coupling for Reliable Electrical Conduit',
    'emt-rigid-channel-clamp': 'TechnoFlex | EMT & Rigid Channel Clamp for Secure Mounting',
    'brass-adaptor': 'TechnoFlex | Brass Adaptor for Durable Conduit Fittings',
    'default': 'TechnoFlex | Leading Manufacturer of Electrical Conduit Pipes & Accessories',
  };
// Description mapping object
const descriptionMap = {
  'gi-conduit': 'Premium quality GI conduit pipes and accessories. Durable galvanized iron conduits for industrial and commercial applications.',
  '20mm-gi-conduit': '20mm GI conduit pipes for secure electrical installations. Designed for easy and efficient wiring protection.',
  '25mm-gi-conduit': '25mm GI conduit for safe and durable electrical wiring. Reliable protection for industrial and residential use.',
  '32mm-gi-conduit': '32mm GI conduit pipes for heavy-duty applications. Ideal for large-scale installations requiring strong protection.',
  '40mm-gi-conduit': '40mm GI conduit for robust electrical installations. Ensures secure and durable conduit protection for wiring systems.',
  '50mm-gi-conduit': '50mm GI conduit for large-scale installations. Designed for secure and long-lasting electrical protection.',
  'ms-conduit': 'High-grade MS conduit solutions. Robust mild steel conduits engineered for superior performance and longevity.',
  '20mm-ms-conduit': '20mm MS conduit for electrical installations. Provides superior protection for wires in industrial and residential applications.',
  '25mm-ms-conduit': '25mm MS conduit for secure wiring. Perfect for electrical systems that require sturdy, long-lasting conduit solutions.',
  '40mm-ms-conduit': '40mm MS conduit for large-scale installations. Ensures secure, durable protection for electrical wiring.',
  '50mm-ms-conduit': '50mm MS conduit pipes for heavy-duty applications. Provides strong and reliable conduit protection for wiring.',
  'emt-conduit': 'Professional EMT conduit systems. Complete range of electrical metallic tubing solutions for modern installations.',
  '20mm-emt-conduit': '20mm EMT conduit pipes for efficient electrical installations. Lightweight yet durable, perfect for quick setups.',
  '25mm-emt-conduit': '25mm EMT conduit for versatile installations. Offers durable protection for electrical wiring in various settings.',
  '32mm-emt-conduit': '32mm EMT conduit for secure electrical protection. Ideal for use in residential and commercial installations.',
  '40mm-emt-conduit': '40mm EMT conduit pipes for heavy-duty applications. Provides a strong and reliable solution for electrical wiring.',
  '50mm-emt-conduit': '50mm EMT conduit for large-scale electrical systems. Designed for durability and long-term performance.',
  'gi-flexible-conduit': 'GI flexible conduit for secure electrical wiring. Ideal for areas requiring flexible and durable conduit solutions.',
  '1-2-inch-gi-flexible-conduit': '1/2 inch GI flexible conduit for tight and flexible electrical protection. Suitable for a wide range of installations.',
  '3-4-inch-gi-flexible-conduit': '3/4 inch GI flexible conduit for versatile electrical applications. Flexible and durable protection for wiring systems.',
  '1-inch-gi-flexible-conduit': '1-inch GI flexible conduit for efficient and secure electrical protection. Perfect for varied installation needs.',
  'pvc-coated-flexible-conduit': 'PVC coated flexible conduit for enhanced durability. Provides additional protection against environmental factors.',
  '1-2-inch-pvc-coated-gi-flexible-conduit': '1/2 inch PVC coated GI flexible conduit. Offers secure, long-lasting protection against harsh environments.',
  '3-4-inch-pvc-coated-gi-flexible-conduit': '3/4 inch PVC coated GI flexible conduit. Suitable for applications requiring extra durability and protection.',
  'liquid-tight-flexible-conduit': 'Liquid-tight flexible conduit for water-resistant and secure electrical installations. Ideal for exposed wiring systems.',
  '1-2-inch-liquid-tight-flexible-conduit': '1/2 inch liquid-tight flexible conduit. Provides superior water-tight protection for electrical wiring in damp environments.',
  '3-4-inch-liquid-tight-flexible-conduit': '3/4 inch liquid-tight flexible conduit. Perfect for electrical systems in moist or exposed areas.',
  'stainless-steel-flexible-conduit': 'Stainless steel flexible conduit for superior durability and corrosion resistance. Designed for extreme environments.',
  '1-2-inch-stainless-steel-flexible-conduit': '1/2 inch stainless steel flexible conduit. Ensures long-lasting, secure electrical protection in harsh environments.',
  '3-4-inch-stainless-steel-flexible-conduit': '3/4 inch stainless steel flexible conduit. Ideal for tough installations requiring extra strength and corrosion resistance.',
  'aluminum-flexible-conduit': 'Aluminum flexible conduit for lightweight and durable electrical protection. Ideal for flexible wiring solutions in light industrial applications.',
  '1-2-inch-aluminum-flexible-conduit': '1/2 inch aluminum flexible conduit. A lightweight and durable conduit solution for flexible wiring installations.',
  '3-4-inch-aluminum-flexible-conduit': '3/4 inch aluminum flexible conduit. Provides durable, easy-to-install protection for electrical wiring systems.',
  'polyamide-flexible-conduit': 'Polyamide flexible conduit for high-strength, flexible electrical protection. Suitable for various industrial and commercial applications.',
  '1-2-inch-polyamide-conduit': '1/2 inch polyamide flexible conduit. Designed for superior performance in industrial electrical installations.',
  '3-4-inch-polyamide-conduit': '3/4 inch polyamide flexible conduit. Ideal for use in demanding electrical installations requiring durability and flexibility.',
  'pvc-rigid-conduit': 'PVC rigid conduit pipes for electrical installations. Provides durable protection for electrical wiring in both residential and commercial settings.',
  '20mm-pvc-rigid-conduit': '20mm PVC rigid conduit for secure and compact installations. Offers reliable protection for electrical wiring systems.',
  '25mm-pvc-rigid-conduit': '25mm PVC rigid conduit for versatile electrical installations. Ensures long-lasting protection for wiring systems.',
  '32mm-pvc-conduit': '32mm PVC conduit for industrial-grade installations. Provides robust protection and safety for electrical wiring.',
  'industrial-products': 'A wide range of industrial electrical products, including conduit systems, junction boxes, and more, designed for high-performance applications.',
  'class-3-conduit': 'TechnoFlex offers durable and high-quality Class 3 conduit pipes designed to provide superior protection for electrical wiring. Ideal for medium-duty electrical installations in both residential and commercial environments.',
  'class-4-conduit': 'TechnoFlex Class 4 conduit pipes are built for heavy-duty electrical installations, providing optimal protection for wiring in industrial and commercial settings. Designed to withstand harsh environments and ensure long-lasting durability.',
  'liquid-tight-flexible-conduit': 'TechnoFlex Liquid Tight Flexible Conduit offers exceptional protection for electrical wiring in wet, damp, or exposed environments. Designed with a flexible yet durable construction, it ensures your electrical systems stay safe from moisture, chemicals, and other harsh conditions.',
  // Other existing descriptions...
  // Adding the new products with their descriptions
  'one-way-gi-junction-box': 'One Way GI Junction Box for safe and efficient wiring connections in electrical systems.',
  'two-way-gi-junction-box': 'Two Way GI Junction Box for reliable electrical installations and branching connections.',
  'three-way-gi-junction-box': 'Three Way GI Junction Box designed for complex electrical installations requiring multiple connections.',
  'gi-bend': 'GI Bend for smooth conduit routing in electrical systems. Ideal for turns and corners in conduit setups.',
  'gi-coupler': 'GI Coupler for connecting conduit sections securely. Ensures a reliable and safe electrical conduit connection.',
  'saddle-bar': 'Saddle Bar for securely mounting conduits on surfaces. Ideal for fast and safe conduit installation.',
  'half-saddle': 'Half Saddle for stable and easy conduit installation. Perfect for securing conduits to surfaces.',
  'gi-lock-nut': 'GI Lock Nut for ensuring tight, secure connections in electrical conduit systems.',
  'gi-tee': 'GI Tee for branching electrical conduits. Allows easy and safe junctioning of wiring systems.',
  'gi-elbow': 'GI Elbow for turning electrical conduits in precise angles. Ideal for routing wiring around obstacles.',
  'ms-bends': 'MS Bends for flexible conduit routing. Perfect for use in systems requiring durable and adaptable bends.',
  'ms-surface-junction-boxes': 'MS Surface Junction Boxes for durable electrical installations on surfaces. Provides reliable access for wiring connections.',
  'ms-deep-junction-boxes': 'MS Deep Junction Boxes for secure and organized installations in deep electrical systems.',
  'ms-couplers': 'MS Couplers for connecting MS conduits securely. Designed for reliability in industrial electrical systems.',
  'ms-inspection-bends': 'MS Inspection Bends for easy access to electrical conduits. Ideal for maintenance and inspection tasks.',
  'ms-elbows': 'MS Elbows for smooth and secure conduit turns. Used for routing electrical wiring with ease.',
  'ms-inspection-t': 'MS Inspection T for convenient junction and inspection of conduit systems.',
  'zinc-die-cast-coupler': 'Zinc Die Cast Coupler for strong, corrosion-resistant conduit connections.',
  'nickel-plated-brass-adaptor': 'Nickel Plated Brass Adaptor for high-quality conduit connections in demanding applications.',
  'liquid-tight-straight-connector': 'Liquid Tight Straight Connector for connecting electrical conduits securely in wet environments.',
  'mount-clamp': 'Mount Clamp for securely fastening electrical conduits to surfaces. Ensures stability and safety.',
  'four-way-gi-junction-box': 'Four Way GI Junction Box for versatile conduit connections in complex electrical systems.',
  'circular-junction-box-one-way': 'Circular Junction Box One Way for simple and reliable electrical installations.',
  'circular-doom-cover': 'Circular Doom Cover for protecting junction boxes and wiring systems.',
  'circular-junction-box-two-way': 'Circular Junction Box Two Way for efficient and secure wiring installations.',
  'circular-back-outlet': 'Circular Back Outlet for clean and effective electrical outlet installations.',
  'circular-junction-box-three-way': 'Circular Junction Box Three Way for multi-directional conduit connections in electrical systems.',
  'circular-junction-box-four-way': 'Circular Junction Box Four Way for comprehensive conduit system junctions.',
  'circular-junction-box-u-type': 'Circular Junction Box U Type for versatile and reliable electrical installations.',
  'circular-junction-box-angle-way': 'Circular Junction Box Angle Way for angle conduit connections in tight spaces.',
  'circular-junction-box-h-type': 'Circular Junction Box H Type for multiple conduit connections in industrial electrical systems.',
  'spacer-bar-saddle': 'Spacer Bar Saddle for mounting conduits with proper spacing, ideal for secure conduit installation.',
  'normal-bend': 'Normal Bend for smooth, easy conduit turns. Ideal for residential and commercial wiring systems.',
  'plain-saddle': 'Plain Saddle for efficient conduit mounting on surfaces. Ideal for basic installations.',
  'hex-lock-nut': 'Hex Lock Nut for strong, secure conduit fittings and connections in electrical systems.',
  'gi-coupler': 'GI Coupler for secure connection of GI conduit sections in electrical installations.',
  'gi-doom-cover': 'GI Doom Cover for protecting junction boxes from dust, moisture, and debris.',
  'hex-reducer': 'Hex Reducer for adapting conduit sizes in electrical systems.',
  'earthing-strip-saddle': 'Earthing Strip Saddle for securing earthing strips and ensuring electrical safety.',
  'switch-and-socket-box': 'Switch and Socket Box for easy installation and secure electrical outlets in homes and offices.',
  'emt-one-hole-strap': 'EMT One Hole Strap for securely mounting EMT conduits. Designed for stable installations.',
  'emt-two-hole-strap': 'EMT Two Hole Strap for heavy-duty conduit mounting. Provides extra support for EMT conduits.',
  'squeeze-connector': 'Squeeze Connector for secure electrical connections. Ideal for industrial and commercial use.',
  'set-screw-connector': 'Set Screw Connector for tight and reliable conduit connections.',
  'set-screw-coupling': 'Set Screw Coupling for easy and secure conduit connections in electrical installations.',
  'emt-rigid-channel-clamp': 'EMT & Rigid Channel Clamp for secure mounting and support of electrical conduits.',
  'brass-adaptor': 'Brass Adaptor for converting conduit connections in a range of electrical installations.',
  'default': 'TechnoFlex | Leading Manufacturer of Electrical Conduit Pipes & Accessories',
};
const keywordsMap = {
  'gi-conduit': 'electrical conduit manufacturer, industrial conduit supplier, GI conduit, 20mm GI conduit, 25mm GI conduit, 32mm GI conduit, 40mm GI conduit, 50mm GI conduit, galvanized iron conduit, flexible conduit, pipe fittings',
  '20mm-gi-conduit': '20mm GI conduit, electrical wiring protection, galvanized iron conduit, 20mm conduit supplier, electrical conduit pipes',
  '25mm-gi-conduit': '25mm GI conduit, durable electrical conduit, 25mm conduit pipes, industrial conduit, secure wiring',
  '32mm-gi-conduit': '32mm GI conduit, heavy-duty conduit, electrical conduit supplier, 32mm conduit for installation',
  '40mm-gi-conduit': '40mm GI conduit, strong electrical conduit, conduit for industrial use, heavy-duty 40mm conduit',
  '50mm-gi-conduit': '50mm GI conduit, large-scale electrical conduit, 50mm GI conduit pipes, industrial conduit supplier',
  
  'ms-conduit': 'MS conduit, mild steel conduit, electrical conduit pipes, MS electrical conduit, secure wiring solutions',
  '20mm-ms-conduit': '20mm MS conduit, electrical wiring protection, mild steel conduit, 20mm conduit for secure installations',
  '25mm-ms-conduit': '25mm MS conduit, durable electrical conduit, 25mm MS pipe for wiring, industrial MS conduit',
  '40mm-ms-conduit': '40mm MS conduit, heavy-duty electrical conduit, 40mm MS conduit pipe, reliable MS conduit for industrial use',
  '50mm-ms-conduit': '50mm MS conduit, industrial electrical conduit, 50mm MS conduit for complex installations',

  'emt-conduit': 'EMT conduit, electrical metallic tubing, EMT conduit pipe, electrical wiring protection, conduit fittings',
  '20mm-emt-conduit': '20mm EMT conduit, electrical metallic tubing 20mm, secure wiring with EMT conduit, 20mm conduit pipe',
  '25mm-emt-conduit': '25mm EMT conduit, reliable electrical metallic conduit, 25mm EMT pipe for secure installations',
  '32mm-emt-conduit': '32mm EMT conduit, heavy-duty EMT conduit, 32mm conduit for electrical systems',
  '40mm-emt-conduit': '40mm EMT conduit, electrical conduit for wiring, 40mm EMT pipe for industrial installations',
  '50mm-emt-conduit': '50mm EMT conduit, 50mm electrical conduit for heavy-duty use, EMT conduit pipe',

  'gi-flexible-conduit': 'GI flexible conduit, galvanized iron flexible conduit, electrical flexible conduit, GI conduit pipe, flexible wiring solutions',
  '1-2-inch-gi-flexible-conduit': '1/2 inch GI flexible conduit, flexible conduit for 1/2 inch piping, durable GI conduit',
  '3-4-inch-gi-flexible-conduit': '3/4 inch GI flexible conduit, flexible conduit for 3/4 inch pipe, galvanized iron flexible conduit',
  '1-inch-gi-flexible-conduit': '1 inch GI flexible conduit, industrial electrical flexible conduit, GI flexible conduit for secure connections',

  'pvc-coated-flexible-conduit': 'PVC coated flexible conduit, flexible electrical conduit, PVC conduit protection, conduit pipe with PVC coating',
  '1-2-inch-pvc-coated-gi-flexible-conduit': '1/2 inch PVC coated GI flexible conduit, durable conduit pipe, PVC coated flexible conduit for electrical wiring',
  '3-4-inch-pvc-coated-gi-flexible-conduit': '3/4 inch PVC coated GI flexible conduit, electrical conduit for secure installations, flexible conduit with PVC coating',

  'liquid-tight-flexible-conduit': 'Liquid tight flexible conduit, waterproof conduit, flexible conduit for wiring, liquid-tight electrical conduit',
  '1-2-inch-liquid-tight-flexible-conduit': '1/2 inch liquid tight flexible conduit, liquid-tight flexible conduit for wiring protection',
  '3-4-inch-liquid-tight-flexible-conduit': '3/4 inch liquid tight flexible conduit, waterproof conduit pipe for wiring installations',

  'stainless-steel-flexible-conduit': 'Stainless steel flexible conduit, flexible conduit for secure wiring, stainless steel conduit pipe for electrical installations',
  '1-2-inch-stainless-steel-flexible-conduit': '1/2 inch stainless steel flexible conduit, durable stainless steel conduit for electrical wiring',
  '3-4-inch-stainless-steel-flexible-conduit': '3/4 inch stainless steel flexible conduit, corrosion-resistant stainless steel conduit for wiring',

  'aluminum-flexible-conduit': 'Aluminum flexible conduit, flexible electrical conduit, lightweight aluminum conduit pipe for wiring protection',
  '1-2-inch-aluminum-flexible-conduit': '1/2 inch aluminum flexible conduit, aluminum conduit for wiring, flexible aluminum conduit pipe',
  '3-4-inch-aluminum-flexible-conduit': '3/4 inch aluminum flexible conduit, flexible aluminum conduit for electrical installations',

  'polyamide-flexible-conduit': 'Polyamide flexible conduit, durable polyamide conduit, electrical flexible conduit, polyamide piping for wiring protection',
  '1-2-inch-polyamide-conduit': '1/2 inch polyamide flexible conduit, strong polyamide conduit for wiring installations',
  '3-4-inch-polyamide-conduit': '3/4 inch polyamide flexible conduit, polyamide electrical conduit for secure installations',

  'pvc-rigid-conduit': 'PVC rigid conduit, rigid electrical conduit, PVC conduit pipes, electrical wiring conduit system',
  '20mm-pvc-rigid-conduit': '20mm PVC rigid conduit, compact PVC conduit pipe for wiring installations, electrical conduit for small-scale systems',
  '25mm-pvc-rigid-conduit': '25mm PVC rigid conduit, electrical conduit for wiring systems, durable PVC conduit pipe',
  '32mm-pvc-conduit': '32mm PVC rigid conduit, strong PVC conduit pipes, electrical conduit for large-scale wiring',

  'industrial-products': 'Industrial electrical products, electrical wiring solutions, electrical conduit accessories, industrial conduit fittings, wiring protection',
  
  'class-3-conduit': 'Class 3 conduit, electrical conduit for heavy-duty applications, durable class 3 conduit pipe, industrial electrical conduit',
  'class-4-conduit': 'Class 4 conduit, industrial-grade conduit for electrical wiring, secure class 4 conduit for installations',

  'liquid-tight-flexible-conduit': 'Liquid tight flexible conduit, waterproof conduit, flexible conduit for wiring, liquid-tight electrical conduit',

  'one-way-gi-junction-box': 'One way GI junction box, electrical junction box, GI box for electrical connections, secure conduit junction box',
  'two-way-gi-junction-box': 'Two way GI junction box, electrical junction box, galvanized iron box for wiring solutions',
  'three-way-gi-junction-box': 'Three way GI junction box, electrical junction box for wiring, heavy-duty GI box for multiple connections',
  'gi-bend': 'GI bend, electrical conduit bend, galvanized iron conduit for curved wiring installations',
  'gi-coupler': 'GI coupler, electrical conduit coupler, GI pipe coupler for secure conduit connections',
  'saddle-bar': 'Saddle bar, electrical conduit mounting saddle bar, wiring saddle for easy conduit installation',
  'half-saddle': 'Half saddle, conduit saddle for mounting electrical pipes, half saddle for conduit installation',
  'gi-lock-nut': 'GI lock nut, electrical conduit lock nut, galvanized iron lock nut for conduit fittings',
  'gi-tee': 'GI tee, electrical conduit tee, conduit fitting for branching, galvanized iron tee for conduit systems',
  'gi-elbow': 'GI elbow, electrical conduit elbow, elbow fitting for conduit, GI elbow for wiring connections',
  'ms-bends': 'MS bends, electrical conduit bends, mild steel bends for electrical wiring systems',
  'ms-surface-junction-boxes': 'MS surface junction boxes, mild steel junction boxes for electrical installations',
  'ms-deep-junction-boxes': 'MS deep junction boxes, electrical deep junction box for wiring protection',
  'ms-couplers': 'MS couplers, conduit coupler, mild steel coupler for secure conduit connections',
  'ms-inspection-bends': 'MS inspection bends, mild steel bends for conduit systems, inspection bends for conduit maintenance',
  'ms-elbows': 'MS elbows, electrical conduit elbows, MS elbow fittings for wiring systems',
  'ms-inspection-t': 'MS inspection T, inspection T for conduit systems, mild steel T for electrical conduit',
  'zinc-die-cast-coupler': 'Zinc die cast coupler, die-cast conduit coupler, electrical coupler for secure conduit connections',
  'nickel-plated-brass-adaptor': 'Nickel plated brass adaptor, electrical conduit adaptor, brass adaptor for conduit fittings',
  'liquid-tight-straight-connector': 'Liquid tight straight connector, waterproof conduit connector, straight connector for liquid-tight systems',
  'mount-clamp': 'Mount clamp, electrical conduit mount clamp, secure conduit clamp for wiring systems',
  'four-way-gi-junction-box': 'Four way GI junction box, electrical four-way junction box, GI conduit box for multiple connections',
  'circular-junction-box-one-way': 'Circular junction box one way, electrical circular junction box, one-way junction box for wiring',
  'circular-doom-cover': 'Circular doom cover, electrical conduit cover, doom cover for conduit protection',
  'circular-junction-box-two-way': 'Circular junction box two way, electrical circular junction box, two-way junction box for conduit systems',
  'circular-back-outlet': 'Circular back outlet, electrical conduit back outlet, outlet for circular conduit systems',
  'circular-junction-box-three-way': 'Circular junction box three way, electrical circular junction box, three-way junction box for wiring',
  'circular-junction-box-four-way': 'Circular junction box four way, electrical circular junction box, four-way junction box for multiple connections',
  'circular-junction-box-u-type': 'Circular junction box U type, electrical circular junction box, U-type junction box for conduit wiring',
  'circular-junction-box-angle-way': 'Circular junction box angle way, electrical angle junction box, circular junction box for flexible installations',
  'circular-junction-box-h-type': 'Circular junction box H type, H-type junction box for electrical conduit systems',
  'spacer-bar-saddle': 'Spacer bar saddle, electrical conduit spacer bar, saddle for conduit installation',
  'normal-bend': 'Normal bend, electrical conduit bend, regular bend for conduit systems',
  'reducing-coupler': 'Reducing coupler, conduit reducing coupler, electrical conduit coupler for size adjustment',
  'red-cable-tie': 'Red cable tie, electrical cable tie, red wire tie for cable management',
  'standard-cable-tie': 'Standard cable tie, electrical cable tie, cable tie for wire management',
  'cable-tie-with-screw': 'Cable tie with screw, screw cable tie, electrical cable tie with mounting screw',
  'pvc-end-cap': 'PVC end cap, electrical conduit end cap, PVC cap for conduit protection',
  'gi-end-cap': 'GI end cap, galvanized iron end cap, electrical conduit cap for secure protection',
  'plastic-end-cap': 'Plastic end cap, electrical conduit plastic cap, conduit end cap for wiring systems',
  'electrical-coupler': 'Electrical coupler, conduit coupler, electrical fitting coupler for wiring connections',
  'flexible-conduit-coupler': 'Flexible conduit coupler, conduit fitting coupler, flexible conduit connector for easy installation',
  'elbow-connector': 'Elbow connector, conduit elbow fitting, electrical elbow connector for conduit',
  'pvc-coupler': 'PVC coupler, conduit PVC coupler, electrical conduit fitting for PVC pipes',
  'gi-flexible-conduit-coupler': 'GI flexible conduit coupler, galvanized conduit coupler, electrical coupler for flexible GI conduit',
  'elbow-connector-gi-flexible': 'Elbow connector GI flexible, electrical elbow fitting for GI flexible conduit',
  'coupler-gi-conduit': 'Coupler GI conduit, electrical GI conduit coupler, galvanized conduit fitting for secure connections',
  'liquid-tight-coupler': 'Liquid tight coupler, conduit coupler for waterproof connections, flexible conduit coupler for liquid-tight systems',
  'liquid-tight-connector': 'Liquid tight connector, waterproof conduit connector, liquid-tight electrical connector',
  'lock-nut-gi-conduit': 'Lock nut GI conduit, electrical lock nut, lock nut for GI conduit fittings',
  'lock-nut-pvc-conduit': 'Lock nut PVC conduit, electrical lock nut, lock nut for PVC conduit fittings'
};

const location = useLocation();  // Get the current location (URL) using react-router

  // Function to update the title, description, and keywords meta tags
  const updateMetaTags = () => {
    const path = location.pathname.toLowerCase(); // Get the pathname from location
    const search = location.search.toLowerCase(); // Get query string
    const fullPath = path + ' ' + search;

    // Find matching keyword
    const matchedKeyword = Object.keys(titleMap).find(keyword => fullPath.includes(keyword));

    // Update the title
    const newTitle = matchedKeyword ? titleMap[matchedKeyword] : titleMap['default'];
    document.title = newTitle;

    // Update the meta description
    const newDescription = matchedKeyword ? descriptionMap[matchedKeyword] : descriptionMap['default'];
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', newDescription);
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = 'description';
      newMetaTag.content = newDescription;
      document.head.appendChild(newMetaTag);
    }

    // Update the meta keywords
    const newKeywords = matchedKeyword ? keywordsMap[matchedKeyword] : keywordsMap['default'];
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute('content', newKeywords);
    } else {
      const newMetaKeywordsTag = document.createElement('meta');
      newMetaKeywordsTag.name = 'keywords';
      newMetaKeywordsTag.content = newKeywords;
      document.head.appendChild(newMetaKeywordsTag);
    }
  };

  useEffect(() => {
    updateMetaTags();
  }, [location]);  // Only re-run when the location changes (i.e., on navigation)

  return null;
};

export default DynamicTitleUpdater;
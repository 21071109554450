
import { RotateLoader } from "react-spinners";
import "./loader.css";

const Loader = () => {
    return (
        <div className="loader-container">
            <RotateLoader
                color="#14eeee"
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <div className="loading-text">Loading...</div>
        </div>
    );
}

export default Loader;

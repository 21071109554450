// components/SecurityWrapper.jsx
import React, { useEffect } from 'react';

const SecurityWrapper = ({ children }) => {
  useEffect(() => {
    // Disable specific console methods without redefining the console object
    const disableConsole = () => {
      console.log = () => {};
      console.info = () => {};
      console.warn = () => {};
      console.error = () => {};
      console.debug = () => {};
      console.table = () => {};
      console.trace = () => {};
      console.clear = () => {};
    };

    // Block a broader set of developer tool and debug-related shortcuts
    const blockDevToolsShortcuts = (e) => {
      // Block standard DevTools shortcuts
      const forbiddenCombos = [
        { ctrl: true, shift: true, key: 'I' },
        { ctrl: true, shift: true, key: 'J' },
        { ctrl: true, shift: true, key: 'C' },
        { ctrl: true, key: 'U' },
        { ctrl: true, shift: true, key: 'K' },
        { ctrl: true, key: 'S' },
        { key: 'F12' },
        { alt: true, key: 'F4' },  // Prevent closing the window
        { alt: true, key: 'Tab' }  // Prevent Alt+Tab
      ];

      // Check if the key combination matches any of the forbidden combinations
      for (let combo of forbiddenCombos) {
        if (
          e.key.toUpperCase() === combo.key &&
          ((combo.ctrl && e.ctrlKey) || !combo.ctrl) &&
          ((combo.shift && e.shiftKey) || !combo.shift) &&
          ((combo.alt && e.altKey) || !combo.alt)
        ) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
    };

    // Block right-clicking and context menus
    const blockRightClick = (e) => {
      e.preventDefault();
      return false;
    };

    // Block copy-paste and text selection shortcuts
    const blockCopyPaste = (e) => {
      if (
        e.ctrlKey &&
        (e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a')
      ) {
        e.preventDefault();
        return false;
      }
    };

    // Apply security measures
    disableConsole();
    document.addEventListener('contextmenu', blockRightClick);
    document.addEventListener('keydown', blockDevToolsShortcuts);
    document.addEventListener('keydown', blockCopyPaste);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', blockRightClick);
      document.removeEventListener('keydown', blockDevToolsShortcuts);
      document.removeEventListener('keydown', blockCopyPaste);
    };
  }, []);

  return <>{children}</>;
};

export default SecurityWrapper;
